import React from 'react';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginButton from '../loginButton/LoginButton';
import './Header.css';

function Header (props) {

    return (
        <header className={!props.logged_in ? "header-external" : ""}>
            <Container className="logo">
              <Row>
                <Col>
                  <img
                      className={!props.logged_in ? "ext-logo-img" : ""}
                      src={props.logo}
                      alt="Digia"
                  />
                </Col>
                <Col>
                  <span
                      className={!props.logged_in ? "ext-logo-text" : ""}>
                      {props.text}
                  </span>
                </Col>
                <Col align="end">
                  <LoginButton />
                </Col>
              </Row>
            </Container>
        </header>
    )


}

export default Header;
