import React, { useState } from "react";
import { useStore } from "../../utils/store";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EntryEdit(props) {

  const entry = props.selectedEntryForEdit;
  const [curEntry, setCurEntry] = useState(entry);

  const { state } = useStore();

  if(curEntry.itemId !== entry.itemId) {
    setCurEntry(entry);
  }

  const admin = state.user.groups.includes('tr_admin');
  const manager = state.user.groups.includes(`tr_cc_${curEntry.costCenterNumber}`);

  const makeCcValues = (allDef, curEntry) => {
    var defaults = allDef.filter(item => item.groupName === 'COSTCENTER')
    .filter(i => (admin || state.user.managerOf.some(g => (i.itemId === g))))
    .sort((a,b) => a.itemId - b.itemId);

    defaults = defaults.filter(item => (!props.ccList.some(entry => (item.itemName === entry.costCentr && item.itemId === entry.costCenterNumber ))))
    if(!defaults.some(item => (curEntry.costCenter === item.itemName))) {
      defaults = [{ itemId: curEntry.costCenterNumber, itemName: curEntry.costCenter }].concat(defaults);
    }

    return (defaults);
  }

  const makeCCoptions = () => {
    return (
      ccValues
        .map((entry => (<option key={entry.itemId} value={entry.itemName}
            label= {`${entry.itemId} ${entry.itemName}`} /> )
      ))
    );
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    props.saveTechEdit(curEntry)
  }

  const handlePassivate = () => {
    const r = window.confirm(
      `Do you really want to delete this entry ?`);
    if(r === true){
      props.passivateEntry(curEntry);
    }
  }

  const makeDelivOptions = () => {
    var defaults = state.defaults.filter(item => item.groupName === 'DELIVERYAREA')
    .sort((a,b) => a.itemId - b.itemId);

    if(!defaults.some(item => (curEntry.deliveryArea === item.itemName ))) {
      defaults = [{itemId: 'X', itemName: curEntry.deliveryArea}].concat(defaults);
    }

    defaults = [undefined].concat(defaults);
    return (
      defaults.map((entry => {
        if(entry) {
          return(<option key={entry.itemId} value={entry.itemName}
            label= { entry.itemName } />)
        } else {
          return(<option key="" value="">Choose...</option>)
        }
      }))
    );
  }

  const valid = (id, ref) => {
    return typeof ref[id] !== 'undefined';
  }

  const validate = (entry) => {
    if(!entry.name || !valid(entry.ring, state.rings)
      || !valid(entry.quadrant, state.quadrants)
      || !valid(entry.usage, state.usage)
      || !valid(entry.recruitingNeeds, state.recruiting_needs)
      || !valid(entry.trend, state.trend) ) {
        return false;
    }
    return true;
  }
  const ccValues = makeCcValues(state.defaults, curEntry);
  const ccOptions = makeCCoptions();
  const delivOptions = makeDelivOptions();

  const makeDateOptions = (nbr=2,hist=0, curValue) => {
    var addCurrent = true;
    const today = new Date();
    const currentYear = today.getFullYear();
    const half = Math.floor((today.getMonth() + 6) / 6);
    var range = Array(nbr).fill(1).map((el,ix) => {
      var puolikas= ix+half-hist;
      var ovf = Math.ceil((puolikas-2)/2.0);
      const year=currentYear+ovf;
      const data = `${year} H${puolikas-ovf*2}`;
      if(data === curEntry) {
        addCurrent = false;
      }
      return(<option key={ix} value={data} label={data}/>)
    });
    if(addCurrent) {
      range = [<option key="9999" value={curValue} label={curValue}/>].concat(range);
    }
    return range;
  }

  const cancelEdit = () => {
    props.cancelTechEdit(curEntry);
  }


  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === 'costCenter') {
        const costCenter= ccValues.filter(item => item.itemName === value);
        if(costCenter.length > 0) {
          setCurEntry({ ...curEntry,  costCenterNumber: costCenter[0].itemId, costCenter: costCenter[0].itemName });
        }
    } else {
      setCurEntry({ ...curEntry,  [name]: value });
    }
  }

  return (
    <Form onSubmit={handleSubmit} id="entryeditForm">
      <h1>{curEntry.costCenterNumber} {curEntry.costCenter}</h1>
      <Row>
        <div className="col-sm-9 " />
        <div className="col-sm-3 formButtons">
          <Button className="btn-red" size="sm" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button className="btn-blue buttonRight" size="sm" variant="primary"
            type="submit" disabled = {! validate(curEntry) }>
            Save
          </Button>
          <Button className="btn-blue buttonRight" size="sm"
            onClick={handlePassivate} disabled = {!( manager || admin)}>
            Delete
          </Button>
        </div>
      </Row>
      <Row>
        <Form.Label column sm={2}>Origin</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
              column="sm"
              name="costCenter" onChange={handleChange} defaultValue={curEntry.costCenter}
              readOnly={!admin}
          >
              {ccOptions}
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Contact persons</Form.Label>
        <Col lg={3}>
          <Form.Control type="text" name="contactPerson" onChange={handleChange} defaultValue={curEntry.contactPerson}/>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Date</Form.Label>
        <Col lg={3}>
          <Form.Control as="select" name="date" onChange={handleChange} defaultValue={curEntry.date}>
            { makeDateOptions(2,0,curEntry.date) }
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Label</Form.Label>
        <Col lg={3}>
          <Form.Control type="text" name="labels" onChange={handleChange} defaultValue={curEntry.labels}/>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Category</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="quadrant" onChange={handleChange} defaultValue={curEntry.quadrant}
          >
            {Object.entries(state.quadrants).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Delivery area</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
              column="sm"
              name="deliveryArea" onChange={handleChange} defaultValue={curEntry.deliveryArea}
            >
              {delivOptions}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Ring</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="ring" onChange={handleChange} defaultValue={curEntry.ring}
          >
            <option value="">Choose...</option>
            {Object.entries(state.rings).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
            <option value="4">Choose...</option>
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Cloud provider</Form.Label>
        <Col lg={3}>
          <Form.Control type="text" name="cloudProvider" onChange={handleChange} defaultValue={curEntry.cloudProvider}/>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Delta</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="delta" onChange={handleChange} defaultValue={curEntry.delta}
          >
            {Object.entries(state.delta).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Recruiting need</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="recruitingNeeds" onChange={handleChange} defaultValue={curEntry.recruitingNeeds}
          >
            <option value="">Choose...</option>
            {Object.entries(state.recruiting_needs).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Trend</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="trend" onChange={handleChange} defaultValue={curEntry.trend}
          >
            <option value="">Choose...</option>
            {Object.entries(state.trend).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Usage</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="usage" onChange={handleChange} defaultValue={curEntry.usage}
          >
            <option value="">Choose...</option>
            {Object.entries(state.usage).map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>)}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Number of specialists</Form.Label>
        <Col lg={3}>
          <Form.Control type="number" name="numbersOfSpecialists" onChange={handleChange} defaultValue={curEntry.numbersOfSpecialists}/>
        </Col>
        <Form.Label column sm={2}>Rows in history</Form.Label>
        <Form.Label column sm={3}>{curEntry.history ? curEntry.history.length : '0'}</Form.Label>
      </Row>
      <Row>
        <Form.Label column sm={2}>Related to Digia strategy</Form.Label>
        <Col lg={3} className="radioButtons" >
          <Form.Check name='relatedToDigiaStrategyStream' inline label="Yes" type='radio' id="strategyYes" value="yes" onChange={handleChange} defaultChecked={curEntry.relatedToDigiaStrategyStream}/>
          <Form.Check name='relatedToDigiaStrategyStream' inline label="No" type='radio' id="strategyNo" value="" onChange={handleChange} defaultChecked={!curEntry.relatedToDigiaStrategyStream}/>
        </Col>
        <Form.Label column sm={2}>Sell knowledge</Form.Label>
        <Col lg={3} className="radioButtons">
          <Form.Check name='salesExpertice' inline label="Yes" type='radio' id="sellKowledgeYes" value="yes" onChange={handleChange} defaultChecked={curEntry.salesExpertice} />
          <Form.Check name='salesExpertice' inline label="No" type='radio' id="sellKowledgeNo" value="" onChange={handleChange} defaultChecked={!curEntry.salesExpertice} />
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Sell as product, service or <br/> solution </Form.Label>
        <Col lg={3} className="radioButtons">
          <Form.Check name='salesProductSolutionOrService' inline label="Yes" type='radio' id="sellAsYes" value="yes" onChange={handleChange} defaultChecked={curEntry.salesProductSolutionOrService} />
          <Form.Check name='salesProductSolutionOrService' inline label="No" type='radio' id="sellAsNo" value="" onChange={handleChange} defaultChecked={!curEntry.salesProductSolutionOrService} />
        </Col>
        <Form.Label column sm={2}>Sell as</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="soldAsNewServiceOrPartOfOtherService" onChange={handleChange} defaultValue={curEntry.soldAsNewServiceOrPartOfOtherService}
          >
            <option value="">Choose...</option>
            <option value="New Sales">New Sales</option>
            <option value="Support Sales">Support Sales</option>
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Description for usage</Form.Label>
        <Col lg={9}>
          <Form.Control
            as="textarea"
            name="descriptionOfUsage" onChange={handleChange} defaultValue={curEntry.descriptionOfUsage}
          >
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Comment for trend</Form.Label>
        <Col lg={9}>
          <Form.Control
            as="textarea"
            name="comment" onChange={handleChange} defaultValue={curEntry.comment}
          >
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Partnership</Form.Label>
        <Col lg={9}>
          <Form.Control
            as="textarea"
            name="partnership" onChange={handleChange} defaultValue={curEntry.partnership}
          >
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
}
export default EntryEdit;
