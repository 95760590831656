import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import * as _ from "lodash";
import "./TechPopupBody.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "../../utils/store";
import EntryEdit from "./EntryEdit"
import { id2name, yesNo } from "../../utils/helpers"

const CcRow = ({ lab1, dat1, lab2, dat2 }) => (
  <Row className="costCenterDataRow">
    <div className="col-lg-2 costCenterDataLabel">
      {lab1}
    </div>
    <div className="col-lg-4">
      {dat1}
    </div>
    <div className="col-lg-2 costCenterDataLabel">
      {lab2}
    </div>
    <div className="col-lg-4">
      {dat2}
    </div>
  </Row>
)

const CcRowSingle = ({ lab1, dat1 }) => (
  <Row className="costCenterDataRow">
    <div className="col-lg-2 costCenterDataLabel">
      {lab1}
    </div>
    <div className="col-lg-10">
      {dat1}
    </div>
  </Row>
)

const ConditionalButton = ({ disabled, onClick, text }) => {
  if(disabled) {
    return(<></>);
  } else  {
    return(
      <Button className="btn-blue buttonRight" size="sm" onClick= {onClick}>
        {text}
      </Button>
    );
  }
}

function TechPopupBody( props ) {
  const { state, updateEntry, passivateEntry } = useStore();

  const [selectedEntry, setSelectedEntry] = useState(props.selectedEntry);
  const [selectedEntryForEdit, setSelectedEntryForEdit] = useState("");

  const cancelTechEdit = (entry) => {
    const modified = !_.isEqual(entry, selectedEntryForEdit);
    if(!modified || window.confirm(`Discard modifications ?`) === true) {
      setSelectedEntryForEdit("");
    }
  }

  const managerCanAdd = () => {
    var ret = false;
    for (const cc of state.user.managerOf) {
      if(!selectedEntry.details.some(d => d.costCenterNumber === cc)) {
        ret = true;
        break;
      }
    }
    return ret;
  }
  const saveTechEdit = async (entry) => {
    const modified = !_.isEqual(entry, selectedEntryForEdit)
    setSelectedEntryForEdit("");
    if(modified) {
      const response= await updateEntry(entry)
      if(response.result === 'OK') {
        setSelectedEntry(response.data)
      }
    }
  }

  const passivate = async (entry, all = false) => {
    if(all && entry.history ) {
      for (const item of entry.history ){
        await passivateEntry(item)
      }
    }
    const response= await passivateEntry(entry)
    setSelectedEntryForEdit("");
    if(response.result === 'OK') {
      if(response.data) {
        setSelectedEntry(response.data)
      } else {
        props.close();
      }
    }
  }

  const onPassivate = (entry) => {
    const r = window.confirm(
      `Do you really want to delete all entries of ${entry.name} for ${entry.costCenter}(${entry.costCenterNumber}) ?`);
    if(r === true){
      passivate(entry,true);
    }
  }

  const clone = (entry) => {
    const cloned = { ...entry,costCenter: 'choose', costCenterNumber: '' };
    delete cloned.itemId;
    delete cloned.history;
    delete cloned.contactPerson;
    delete cloned.labels;
    delete cloned.recruitingNeeds;
    //delete cloned.trend;
    //delete cloned.usage;
    selectedEntry.details = selectedEntry.details.concat(cloned);
    setSelectedEntry(selectedEntry);
    return cloned;
  }

  return (
    <Modal.Body className="modalBody">
      { selectedEntryForEdit !== "" && (

        <EntryEdit
          selectedEntryForEdit = {selectedEntryForEdit}
          ccList = {selectedEntry.details.map(entry => ({ costCentr: entry.costCenter, costCenterNumber: entry.costCenterNumber }))}
          cancelTechEdit = {cancelTechEdit}
          saveTechEdit = {saveTechEdit}
          passivateEntry = {passivate}/>
      )}

      {selectedEntry.details.map((entry, i) => {

        return (
          <div key={i}>
            {selectedEntryForEdit.itemId !== entry.itemId && (
              <Accordion>
                <Card className="info-card" >
                  <Row className="modalHeadLine">
                    <div className="col-lg-3 costCenterLabel">
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        <FontAwesomeIcon
                          className="fa-w-5 fa-2x"
                          icon={faAngleDown}
                        />
                      </Accordion.Toggle>
                        {entry.costCenter}
                        {entry.costCenterNumber !== "" &&
                          "(" + entry.costCenterNumber + ")"}
                    </div>
                    <div className="col-lg-1">
                      {entry.ring !== 4 && entry.ring !== -1 && (
                        <p style={{color: state.rings[entry.ring].color}}>
                            {state.rings[entry.ring].name}{" "}
                        </p>)}
                    </div>
                    <div className="col-lg-3">
                      {entry.quadrant !== -1 && state.quadrants[entry.quadrant].name}
                    </div>
                    <div className="col-lg-2">
                      {entry.contactPerson}
                    </div>
                    <div className="col-lg-3 costCenterRowButtons">
                      <ConditionalButton
                        onClick = {() => setSelectedEntryForEdit(entry)}
                        disabled = {
                          selectedEntryForEdit!=="" || !(state.user.groups.includes('tr_admin')
                          || state.user.groups.includes(`tr_cc_${entry.costCenterNumber}`))
                        }
                        text = "Edit"
                      />
                      <ConditionalButton
                        onClick={() => setSelectedEntryForEdit(clone(entry))}
                        disabled = {
                          selectedEntryForEdit!=="" || !(state.user.groups.includes('tr_admin')
                          || managerCanAdd() )
                        }
                        text="Clone"
                      />
                      <ConditionalButton
                        onClick={() => onPassivate(entry)}
                        disabled = {
                          selectedEntryForEdit!=="" || !(state.user.groups.includes('tr_admin')
                          || state.user.groups.includes(`tr_cc_${entry.costCenterNumber}`))
                        }
                        text="Delete"
                      />
                    </div>
                  </Row>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <CcRow
                        lab1='Date' dat1={entry.date}
                        lab2='Label' dat2={entry.labels}
                      />
                      <CcRow
                        lab1='Delta' dat1={id2name(entry.delta, state.delta)}
                        lab2='Cloud Provider' dat2={entry.cloudProvider}
                      />
                      <CcRow
                        lab1='Usage' dat1={id2name(entry.usage, state.usage)}
                        lab2='Trend' dat2={id2name(entry.trend, state.trend)}
                      />
                      <CcRow
                        lab1='Numbers of specialists' dat1={entry.numbersOfSpecialists}
                        lab2='Requiting need' dat2={id2name(entry.recruitingNeeds, state.recruiting_needs)}
                      />
                      <CcRow
                        lab1='Related to Digia strategy' dat1={yesNo(entry.relatedToDigiaStrategyStream)}
                        lab2='Delivery Area' dat2={entry.deliveryArea}
                      />
                      <CcRow
                        lab1='Sell as product, service or solution' dat1={yesNo(entry.salesProductSolutionOrService)}
                        lab2='Sell knowledge' dat2={entry.salesExpertice}
                      />
                      <CcRow
                        lab1='Sell as new service' dat1={yesNo(entry.soldAsNewServiceOrPartOfOtherService)}
                        lab2='Partnership' dat2={entry.partnership}
                      />
                      <CcRowSingle lab1='Open comment for trend' dat1={entry.comment}/>
                      <CcRowSingle lab1='Description of usage' dat1={entry.descriptionOfUsage}/>
                      <CcRowSingle lab1='Rows in history' dat1={entry.history ? entry.history.length : '0'} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )}
            {}
          </div>
        );
      })}
    </Modal.Body>
  );
}
export default TechPopupBody;
