import React, { useState } from "react";
import * as _ from "lodash";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import TechPopupBody from "./TechPopupBody";
import "./QuadrantRings.css";

function QuadrantRings(props) {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [selectedEntry, setSelectedEntry] = useState("");

  let filteredEntries = props.entries.filter(Boolean);
  let entries = _.filter(filteredEntries, (entry) => {
    if (entry.ring !== props.ring.id) {
      return false;
    }

    if (entry.quadrant !== props.quadrant.id) {
      return false;
    }

    return true;
  });

  if (entries.length <= 0) {
    return <div></div>;
  }
  const handleTechDataShow = (entry) => {
    setSelectedEntry(entry);
    handleShowModal();
  };
  return (
    <div>
      <h6>
        <Badge variant={props.ring.class_name}>{props.ring.name}</Badge>
      </h6>

      <ul style={{ margin: "0 0 0 15px", padding: 0 }}>
        {entries.map((entry) => {
          let icon = null;
          switch (entry.delta) {
            case -1:
              icon = faArrowDown;
              break;
            case 1:
              icon = faArrowUp;
              break;
            case 2:
              icon = faPlusCircle;
              break;
            default:
          }

          return (
            <li key={entry.label} style={{ fontSize: "10px" }}>
              <div
                className="quardantLink"
                onClick={() => handleTechDataShow(entry)}
              >
                {entry.label}
                {icon ? (
                  <FontAwesomeIcon
                    style={{ marginLeft: "5px", color: "#606060" }}
                    icon={icon}
                  />
                ) : (
                  <></>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <Modal
        dialogClassName="modal-90w"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="techModalHeader">
            <Row>
              <div className="col-md-4">
                {selectedEntry.label}
              </div>
              <div className="col-md-6">
              <a
                className="googleLink"
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "http://www.google.com/search?q=" + selectedEntry.label
                }
              >
                Google
              </a>
                <a
                  className="googleLink trendLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                  "https://trends.google.fi/trends/explore?date=all&q=" +
                    selectedEntry.label
                  }
                >
                  Google Trends
                </a>
              </div>
              {}
            </Row>
          </Modal.Title>
        </Modal.Header>
        {props.logged_in &&  <TechPopupBody selectedEntry={selectedEntry} 
          close={handleCloseModal} />
        }
        <Modal.Footer>
          <Button className="btn-blue" onClick={handleCloseModal} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuadrantRings;
