import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import AddDefaultForm from './AddDefaultForm'

import { useStore } from "../../utils/store";



const EditDefaults = (props) => {
  const { state, deleteDefaults, insertDefault } = useStore();

  const [defaults, setDefaults] = useState([]);

  const handleClose = () => {
    props.setMode("");
  }
  const handleShow = () => {
    props.setMode(props.group);
  }

  useEffect(() => {
    setDefaults(state.defaults
      .filter(item => item.groupName === props.group)
      .sort((a,b) => a.itemId - b.itemId)
    );
  }, [state.defaults, props.group]);

  const handleRemove = async () => {
    const toRemove = defaults.filter(e => e.selected)
      .map(e => ({ groupName: e.groupName, itemId: e.itemId }))
    if (toRemove.length > 0) {
      const r = window.confirm(
        `Do you really want to delete items ${toRemove.map(t => t.itemId)}`);
      if(r === true){
        await deleteDefaults(toRemove);
      }
    }
  }

  if(props.mode==="") {
    return(
      <Button className="btn-blue adminBtn" size="sm" onClick={handleShow}>
        {props.group}
      </Button>
    )
  }

  if (!(props.mode===props.group)) {
    return null;
  }

  const columns = [
    {
      dataField: 'itemId',
      text: 'Item ID',
      sort: true
    },
    {
      dataField: 'itemName',
      text: 'Item Name',
      sort: true
    }
  ];

  const paginatorOptions = {
      page: 1,
      sizePerPage: 10,
      nextPageText: '>',
      prePageText: '<',
      showTotal: true
  };

const selectRow = {
  mode: 'checkbox',
  clickToEdit: true,
  onSelect: (row, isSelect, rowIndex, e) => {
    setDefaults(defaults.map(entry => (row.itemId === entry.itemId ? {...entry, selected: isSelect } : entry )))
  },
  onSelectAll: (isSelect, rows) => {
    const idList = rows.map(row => (row.itemId));
    setDefaults(defaults.map(entry => (idList.includes(entry.itemId) ? {...entry, selected: isSelect } : entry )))
  }
};



  const beforeSave = (oldValue, newValue, row, column, done) => {
    if(oldValue !== newValue) {
      setTimeout(() => {
        const r = window.confirm(`Save ${row.itemId}?`);
        if(r === true){
          const edited = {
            ...row,
            [column.dataField]: newValue
          }
          row.selected=false;
          insertDefault(edited);
          done();
        } else {
          done(false);
        }
      }, 0);
      return { async: true };
    }
  }


  return (
    <>
    <AddDefaultForm group={props.group} />
    <hr />
    <Row>
      <Col>
      <Button className="btn-red adminBtn" size="sm" onClick={handleRemove}>
        Delete selected
      </Button>
      </Col>
      <Col>
      <Button className="btn-blue adminBtn" size="sm" onClick={handleClose}>
        Close editor
      </Button>
      </Col>
    </Row>
    <Row>
      <Col>
      <ToolkitProvider
        keyField='itemId'
        data={ defaults }
        columns={ columns }
      >
        {
          props => (
            <div>
              <BootstrapTable { ...props.baseProps }
                bootstrap4
                striped
                hover
                condensed
                cellEdit={
                  cellEditFactory({
                     mode: 'click',
                     blurToSave: true,
                     beforeSaveCell: beforeSave
                  })
                }
                pagination={ paginationFactory(paginatorOptions) }
                selectRow={ selectRow }
              />
            </div>
          )
        }

      </ToolkitProvider>
      </Col>
    </Row>
    </>
  )
}
export default EditDefaults;
