import React, { useState } from "react";
import { useStore } from "../../utils/store";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



function AddTechForm(props) {

  const entry = {
    name: null,
    costCenter: "Internal",
    costCenterNumber: "0001",
    date: "2021 H2",
    ring: null,
    quadrant: null,
    recruitingNeeds: null,
    usage: null,
    trend: null,
    delta: null
  };
  const [tech, setTech] = useState(entry);

  const { state, updateEntry } = useStore();
  const admin = state.user && state.user.groups.includes('tr_admin');
  //const manager = state.user.groups.includes(`tr_cc_${entry.costCenterNumber}`);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === 'costCenter') {
      if(value === "Public") {
          setTech({ ...tech,  costCenter: value, costCenterNumber: "0000" });
      } else {
        setTech({ ...tech,  costCenter: value, costCenterNumber: "0001" });
      }
    } else {
      setTech({ ...tech,  [name]: value });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response= await updateEntry(tech);
    if(!response.Error) {
      setTech(entry);
      props.setMode("");
    }
    //TODO show error
  }

  const handleClose = () => {
    props.setMode("");
  }
  const handleShow = () => {
    setTech(entry);
    props.setMode("NEWTECH");
  }

  const valid = (id, ref) => {
    return typeof ref[id] !== 'undefined';
  }

  const validate = (entry) => {
    if(!entry || !entry.name || !valid(entry.ring, state.rings)
      || !valid(entry.quadrant, state.quadrants)
      || !valid(entry.usage, state.usage)
      || !valid(entry.recruitingNeeds, state.recruiting_needs)
      || !valid(entry.trend, state.trend) ) {
        return false;
    }
    return unique(entry);
  }

  const unique = (entry) =>
    (entry && entry.name && entry.name.length > 0 && !state.entries.some(item => (item.ring !== 4 && item.label === entry.name)));

  const makeOptions = (element, choose) => {
    var list = [];
    if(choose) {
      list.push(<option key="9999" value={null} label="Choose..."/>);
    }
    list = list.concat(Object.entries(element)
        .map((data,index) => <option key={index} value={data[1].id} label={data[1].name}/>));
    return list;

  }

  const makeDateOptions = (nbr=5,hist=2) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const half = Math.floor((today.getMonth() + 6) / 6);
    const range = Array(nbr).fill(1).map((el,ix) => {
      var puolikas= ix+half-hist;
      var ovf = Math.ceil((puolikas-2)/2.0);
      const year=currentYear+ovf;
      const data = `${year} H${puolikas-ovf*2}`;
      return(<option key={ix} value={data} label={data}/>)
    });
    return range;
  }

  if(props.mode==="") {
    return(
      <Button className="btn-blue adminBtn" size="sm" onClick={handleShow}>
        New Tech
      </Button>
    )
  }

  if (!(props.mode==="NEWTECH")) {
    return null;
  }

  return (
    <Form onSubmit={handleSubmit} id="entryeditForm">
      <Row>
        <div className="col-sm-9 " />
        <div className="col-sm-3 formButtons">
          <Button className="btn-red" size="sm" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-blue buttonRight" size="sm"
            variant="primary" type="submit" disabled={!validate(tech)}>
            Save
          </Button>
        </div>
      </Row>
      <Row>
        <Form.Label column sm={2}>Tech name</Form.Label>
        <Col lg={3}>
          <Form.Control type="text" name="name" onChange={handleChange} defaultValue={tech.name}
            readOnly={!admin} isInvalid={!unique(tech)}/>
        </Col>
        <Form.Label column sm={2}>Contact persons</Form.Label>
        <Col lg={3}>
          <Form.Control className="col-lg-3" type="text" name="contactPerson" onChange={handleChange} defaultValue={tech.contactPerson}/>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Origin</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="costCenter" onChange={handleChange} defaultValue={tech.costCenter}
          >
            <option key={"0000"} value={"Public"} label={"0000 Public"}/>)}
            <option key={"0001"} value={"Internal"} label={"0001 Internal"}/>)}
          </Form.Control>

        </Col>
        <Form.Label column sm={2}>Label</Form.Label>
        <Col lg={3}>
          <Form.Control type="text" name="labels" onChange={handleChange} defaultValue={tech.labels}/>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Date</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="date" onChange={handleChange} defaultValue={tech.date}
          >
            { makeDateOptions(2,0) }
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Category</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="quadrant" onChange={handleChange} defaultValue={tech.quadrant}
            isInvalid = {!tech.quadrant}
          >
            {makeOptions(state.quadrants, true) }
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Recruiting need</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="recruitingNeeds" onChange={handleChange} defaultValue={tech.recruitingNeeds}
            isInvalid = {!tech.recruitingNeeds}
          >
            {makeOptions(state.recruiting_needs, true) }
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Ring</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="ring" onChange={handleChange} defaultValue={tech.ring}
            isInvalid = {!tech.ring}
          >
            {makeOptions(state.rings, true) }
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Trend</Form.Label>
        <Col lg={3}>
          <Form.Control as="select"
            name="trend" onChange={handleChange} defaultValue={tech.trend}
            isInvalid = {!tech.trend}
          >
            {makeOptions(state.trend, true) }
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={2}>Delta</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="delta" onChange={handleChange} defaultValue={tech.delta}
          >
            {makeOptions(state.delta, true) }
          </Form.Control>
        </Col>
        <Form.Label column sm={2}>Usage</Form.Label>
        <Col lg={3}>
          <Form.Control
            as="select"
            name="usage" onChange={handleChange} defaultValue={tech.usage}
            isInvalid = {!tech.usage}
          >
            {makeOptions(state.usage, true) }
          </Form.Control>
        </Col>

      </Row>
      <Row>
        <Form.Label column sm={2}>Comment for trend</Form.Label>
        <Col lg={9}>
          <Form.Control
            as="textarea"
            name="comment" onChange={handleChange} defaultValue={tech.comment}
          >
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
}
export default AddTechForm;
