import React from "react";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import './TechRadar.css'

function TechRadarFilter({
  value,
  filtered,
  onChange,
  multiSelect = false,
}) {

  let arrays = filtered.reduce(
    (arr, itm) => (value.includes(itm.id) ? arr.concat(itm) : arr),
    []
  );

  const handleOnClick = item => {
    if (!value.some((current) => current === item)) {
      if (multiSelect) {
        onChange([...value, item]);
      }
    } else {
      let selectionAfterRemoval = value;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current !== item
      );
      onChange([...selectionAfterRemoval]);
    }
  }

  return arrays.map((item) => (

    <Badge style={{color: "black"}}
      className="filterBadge"
      key={item.id}
      onClick={() => handleOnClick(item.id)}
    >
      {item.name}
      <FontAwesomeIcon
        className="closeIcon"
        icon={faTimes}
      ></FontAwesomeIcon>
    </Badge>
  ));
}
export default TechRadarFilter;
