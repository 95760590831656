import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

import './Info.css';

function Info (props) {
    return (
      <Accordion>
        <Card className="info-card">
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {props.shortText}
            <FontAwesomeIcon className="fa-w-5 fa-2x" icon={faAngleDown}></FontAwesomeIcon>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {props.infoText}
              {props.contact}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

export default Info;
