import React from "react"

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import Amplify from "aws-amplify"
import awsExport from '../../utils/awsExport'
import {StoreProvider } from "../../utils/store"

import PageWrapper from "./PageWrapper"

import "../../theme.scss";

Amplify.configure(awsExport);

function App() {

  return (
    <StoreProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <PageWrapper />
          </Route>
          <Route path="/auth/callback">
            {/* FIXME: show errors if present? */}
            <Redirect to="/" />
          </Route>
          <Route path="/auth/signout">
            {/* FIXME: show errors if present? */}
            <Redirect to="/" />
          </Route>

          {/*  <Route path="/radar/:id" children={<TechnologyPage />} /> */}
        </Switch>
      </Router>
    </StoreProvider>
  );
}

export default App;
