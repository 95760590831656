import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button'
import '../button/Button.css'
import Row from "react-bootstrap/Row";
import EditDefaults from "./EditDefaults"
import AddTechForm from "./AddTechForm"


function AdminPopup() {

  const [show, setShow] = useState(false);
  const [mode, setMode] = useState("");
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setMode("");
    setShow(true);
  }

  const handleMode = (mode) => setMode(mode);

  return (
    <>
      <Button className="btn-red adminBtn" size="sm" onClick={handleShow}>
        Admin Tech Radar
      </Button>

      <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="techModalHeader">
            <Row>
              <div className="col-md-4">
                Admin TechRadar
              </div>
              <div className="col-md-6">
                User
              </div>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <AddTechForm group="NEWTECH" mode={mode} setMode={handleMode}/>
          <EditDefaults group="COSTCENTER" mode={mode} setMode={handleMode}/>
          <EditDefaults group="DELIVERYAREA" mode={mode} setMode={handleMode}/>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-blue" onClick={handleClose} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AdminPopup;
