import * as _ from "lodash";

export const getActiveEntries = state => {
  return _.sortBy(
    _.filter(state.entries, entry => {
      if (state.active_quadrants.length > 0) {
        if (!_.includes(state.active_quadrants, entry.quadrant)) {
          return false;
        }
      }
      if (state.active_rings.length > 0) {
        if (!_.includes(state.active_rings, entry.ring)) {
          return false;
        }
      }

      return true;
    }),
    ["usage"]
  );
};

export const  filterEntriesByString = (key) => {
  const keyToFind = String(key).toLowerCase();

  const compareString = (value) => {
  const stringValue = String(value).toLowerCase();
  return (stringValue.indexOf(keyToFind) !== -1);
  }

  const lookRecursive = (value) => {
  for (const prop in value) {
      const object = value[prop];

      if (typeof object === 'object') {
      if (lookRecursive(object)) {
          return true;
      }
      } else if (compareString(object)) {
      return true;
      }
  }

  return false;
  }
  return row => lookRecursive(row);
}
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA =
      typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB =
      typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export const id2name = (id, lookup) => {
  const row = Object.values(lookup).find(item => (item.id === Number(id)))
  if (row) {
    return row.name
  }
  return `${id}???`
}

export const yesNo = (text) => {
  return (text && text.toUpperCase() === 'YES') ? 'Yes' : 'No'
}
