import React, { useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStore } from "../../utils/store";
import * as _ from "lodash";
import * as jQuery from "jquery";
import { filterEntriesByString, getActiveEntries } from "../../utils/helpers";

import QuadrantTechnologies from "../quadrantTechnologies/QuadrantTechnologies";
import Search from "../search/Search";
import Dropdown from "../dropdown/Dropdown";
import TechRadarFilter from "./TechRadarFilter";
import "./TechRadar.css";



const RADAR_WIDTH = 810;
const RADAR_HEIGHT = 850;
function visualize(props, state, entries, svgEl) {
  if (svgEl.current) {
    // this is after the radar has been already visualized once, need to clean data
    jQuery(svgEl.current).empty();
  }

  window.radar_visualization({
    svg_id: "radar",
    width: RADAR_WIDTH,
    height: RADAR_HEIGHT,
    colors: {
      background: "#fff",
      grid: "#bbb",
      inactive: "#ddd",
    },
    quadrants: state.quadrants,
    rings: state.rings,
    usage: state.usage,
    print_layout: false,
    entries: entries.filter(Boolean),
  });

  // remove height manually (for mobile)
  if (window.innerWidth < 992) {
    svgEl.current.removeAttribute("height");
  }
}

function TechRadar(props) {
  const { state, dispatch } = useStore();
  const svgEl = useRef(null);
  let entries = getActiveEntries(state);
  entries = _.map(entries, (entry) => {
    if (entry.ring === 4) {

      return false;
    }
    if (entry.quadrant === -1) {
        return false;
    }

    entry.quadrant_data = _.find(
      state.quadrants,
      (quadrant) => quadrant.id === entry.quadrant
    );
    entry.ring_data = _.find(
      state.rings,
      (ring) => ring.id === entry.ring
    );
    return entry;
  });
  if (state.active_searchAll) {
    entries = entries.filter(filterEntriesByString(state.active_searchAll));
  }
  useEffect(() => {
    visualize(props, state, entries, svgEl);
  }, [props, state, entries]);
  let quadrants = _.sortBy(state.quadrants, ["sort"]);
  let rings = _.sortBy(state.rings, ["sort"]);
  return (
    <>
      <Container fluid={false}>
        <Search
          onChange={(active) => {
            dispatch({ type: "search_all.new_active", active: active });
          }}
        />
      <Row className="filters">
          {state.logged_in}
          <Col className="col-xs-12 col-lg-2">
            <Dropdown
              title="Quadrants"
              onChange={(active) => {
                dispatch({ type: "quadrant.new_active", active: active });
              }}
              value={state.active_quadrants}
              items={quadrants}
              multiSelect
            />
          </Col>
          <Col className="col-xs-12 col-lg-2">
            <Dropdown
              title="Rings"
              onChange={(active) => {
                dispatch({ type: "ring.new_active", active: active });
              }}
              value={state.active_rings}
              items={rings}
              multiSelect
            />
          </Col>
        </Row>
        <Row noGutters className="chosen_filters">
          <Col>
            {(state.active_rings.length > 0 ||
              state.active_quadrants.length > 0) && (
                <Badge style={{color: "black"}}
                  className="filterBadge"
                  onClick={(active) =>
                    dispatch({ type: "clear_all_active", active: active })
                  }
                >
                  Remove all
                  <FontAwesomeIcon
                    className="trashIcon"
                    icon={faTrashAlt}
                  ></FontAwesomeIcon>
                </Badge>
            )}
            <TechRadarFilter
              value={state.active_rings}
              filtered={rings}
              multiSelect
              onChange={(active) => {
                dispatch({ type: "ring.new_active", active: active });
              }}
            />
            <TechRadarFilter
              value={state.active_quadrants}
              filtered={quadrants}
              multiSelect
              onChange={(active) => {
                dispatch({ type: "quadrant.new_active", active: active });
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col lg={3} style={{ paddingTop: "30px" }}>
            <QuadrantTechnologies
              quadrant={state.quadrants[2]}
              rings={state.rings}
              entries={entries}
              logged_in={state.logged_in}
            />
            <QuadrantTechnologies
              quadrant={state.quadrants[1]}
              rings={state.rings}
              entries={entries}
              logged_in={state.logged_in}
            />
          </Col>

          <Col lg={6}>
            <div style={{ textAlign: "center", margin: "0 auto 20px 0" }}>
              <svg
                ref={svgEl}
                id="radar"
                viewBox={"0 0 " + RADAR_WIDTH + " " + RADAR_HEIGHT}
                style={{ textAlign: "center", width: "100%" }}
              ></svg>
            </div>
          </Col>

          <Col lg={3} style={{ paddingTop: "30px" }}>
            <QuadrantTechnologies
              quadrant={state.quadrants[3]}
              rings={state.rings}
              entries={entries}
              logged_in={state.logged_in}
            />
            <QuadrantTechnologies
              quadrant={state.quadrants[0]}
              rings={state.rings}
              entries={entries}
              logged_in={state.logged_in}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TechRadar;
