import React, { useState } from 'react';

import { useStore } from "../../utils/store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Search.css'

function Search (props) {

    const { dispatch } = useStore();

    const [searchText, setSearchText] = useState("");

    const handleSearchTextChange = (value, active) => {
        setSearchText(value)
        if (value && searchText.length === 0){
            dispatch({ type: "clear_all_active", active: ""})
        }
        props.onChange(value)

    }

    const handleSearchTextClear = () => {
        setSearchText("")
        dispatch({ type: "clear_search_all", active: ""})
    }

    return (
        <Row>
          <Col as="input"
            xs={6}
            align='begin'
            className="col-lg-4 searchField"
            type="text"
            placeholder="Type to search..."
            value={searchText}
            onChange={(e) => handleSearchTextChange(e.target.value)} >
          </Col>
          <Button
                className="btn-gray clearSearchBtn col-lg-1"
                size="sm"
                onClick={handleSearchTextClear}
                disabled={searchText === ""}
            >
                Clear
                <FontAwesomeIcon
                    className="timesIcon"
                    icon={faTimes}
                ></FontAwesomeIcon>
          </Button>
        </Row>
    )

}

export default Search;
