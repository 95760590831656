import React, { createContext, useContext, useReducer, useEffect } from "react";
import { API, Auth } from 'aws-amplify';

const StoreContext = createContext();
const initialState = {
  info_open: false,
  defaults: [],

  active_rings: [],
  rings: {
    0: {
      id: 0,
      active: true,
      name: "Primary I",
      color: "#059D7D",
      class_name: "success",
    },
    1: {
      id: 1,
      active: true,
      name: "Primary II",
      color: "#0883A7",
      class_name: "info",
    },
    2: {
      id: 2,
      active: true,
      name: "Trial",
      color: "#EEBA37",
      class_name: "warning",
    },
    3: {
      id: 3,
      active: true,
      name: "Hold",
      color: "#AA0B0B",
      class_name: "danger",
    },
  },
  usage: {

   0: {id: 0, name: "High (>75%)"},
   1: {id: 1, name: "Medium (35-75%)"},
   2: {id: 2, name: "Small (<35%)"},
   3: {id: 3, name: "Not at all (0%)"},
   4: {id: 4, name: "Unknown"},
  },
  trend: {
    0: {id: 0, name: "Increasing"},
    1: {id: 2, name: "Decreasing"},
    2: {id: 4, name: "Potential"},
    3: {id: 3, name: "New"},
    4: {id: 1, name: "Stable"},
  },
  delta: {
    0: {id: -2, name: "Removed"},
    1: {id: -1, name: "Down"},
    2: {id: 0, name: "Steady"},
    3: {id: 1, name: "Up"},
    4: {id: 2, name: "New"},
  },
  recruiting_needs: {
    0: {id: 0, name: "High"},
    1: {id: 2, name: "Low"},
    2: {id: 1, name: "Medium"},
    3: {id: 5, name: "N/A"},
    4: {id: 4, name: "No idea"},
    5: {id: 3, name: "No needs"},
  },

  active_quadrants: [],

  quadrants: {
    2: { id: 2, name: "Programming Languages, Frameworks & DB", sort: 0 },
    3: { id: 3, name: "DevOps & Tools", sort: 1 },
    1: { id: 1, name: "Products, Platforms & Cloud", sort: 2 },
    0: { id: 0, name: "Integration & API", sort: 3 },
  },
  active_businessUnits: [],
  businessUnits: {
    0: { id: 0, name: "Digia Business Connect"},
    1: { id: 1, name: "Digia Business Platforms"},
    2: { id: 2, name: "Digia Common Services"},
    3: { id: 3, name: "Digia Digital"},
    4: { id: 4, name: "Digia Managed Services"},
    5: { id: 5, name: "Integration"},
  },
  active_costCenter: [],
  costCenter: {
    0: { id: 0, name: "Agile"},
    1: { id: 1, name: "Analytics"},
    2: { id: 2, name: "Business Central"},
    3: { id: 3, name: "Cloud"},
    4: { id: 4, name: "Cloud and Hosting"},
    5: { id: 5, name: "Cloud Delivery Group"},
    6: { id: 6, name: "Commerce"},
    7: { id: 7, name: "CTO Office"},
    8: { id: 8, name: "Custom Solutions"},
    9: { id: 9, name: "D365 BC"},
    10: { id: 10, name: "D365 CE"},
    11: { id: 11, name: "Enterprise"},
    12: { id: 12, name: "Financial Products"},
    13: { id: 13, name: "Integration"},
    14: { id: 14, name: "Integration and API"},
    15: { id: 15, name: "Logistics"},
    16: { id: 16, name: "NetSuite"},
    17: { id: 17, name: "Origin"},
    18: { id: 18, name: "Public"},
    19: { id: 19, name: "Service Operations"},
    20: { id: 20, name: "formattedDataing Services"},
    21: { id: 21, name: "Unified Operations"}

  },
  active_searchAll: "",

  entries: window.techRadarEntries || [],

  state: "initializing", // initializing, loading, ready

  logged_in: null, // true or false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "info.toggle": {
      return { ...state, info_open: !state.info_open };
    }
    case "quadrant.new_active": {
      return { ...state, active_quadrants: action.active };
    }
    case "ring.new_active": {
      return { ...state, active_rings: action.active };
    }
    case "businessUnit.new_active": {
      return { ...state, active_businessUnits: action.active };
    }
    case "costCenter.new_active": {
      return { ...state, active_costCenter: action.active};
    }
    case "search_all.new_active": {
      return { ...state, active_searchAll: action.active};
    }
    case "clear_search_all": {
      return { ...state, active_searchAll: ""};
    }
    case "clear_all_active": {
      return { ...state, active_quadrants: [], active_rings: []}
    }
    case "auth.set_login": {
      return { ...state, logged_in: action.logged_in };
    }
    case "auth.set_user": {
      return { ...state, user: action.user };
    }
    case "data.fetch_data": {
      return { ...state, entries: action.data };
    }
    case "defaults.fetch_data": {
      return { ...state, defaults: action.data };
    }
    case "defaults.remove_data": {
      return {
        ...state,
        defaults:
          state.defaults.filter(
            item => !action.idList.some(
              toDel => toDel.itemId === item.itemId
                && toDel.groupName === item.groupName
            )
          )
      }
    }
    case "defaults.insert_data": {
      const tmpDef = state.defaults.filter(item =>
          (action.data.groupName !== item.groupName ||
            action.data.itemId !== item.itemId));
      return {
        ...state,
        defaults: tmpDef.concat(action.data)
      }
    }
    case "data.update_data_entry": {
      return { ...state,
        entries:
          state.entries.map(entry =>
            entry.label !== action.entry.name ? entry :
              { ...entry,
                details: entry.details.map(
                  row => row.itemId !== action.entry.itemId ?
                    row : action.entry
                )
              }
          )
      };
    }
    case "data.update_tech": {
      return { ...state,
        entries:
          state.entries.map(entry =>
            entry.label !== action.tech.label ? entry : action.tech
          )
      };
    }
    case "data.remove_tech": {
      return { ...state,
        entries:
          state.entries.filter(entry => entry.label !== action.label)
      };
    }

    case "set_state": {
      return { ...state, state: action.state };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "set_state",
      state: "loading",
    });

    const init_load = async () => {
      let logged_in = false;
      let radar_url = "radar/get_external";

      try {
        const authuser = await Auth.currentAuthenticatedUser();

        if(authuser && authuser.signInUserSession
          && authuser.signInUserSession.idToken ) {

          const payload=authuser.signInUserSession.idToken.payload;
          const groups = payload ? payload["cognito:groups"] : [];
          const userId = payload && payload.identities
            ? payload.identities[0].userId
            : null ;
          const managerOf = groups
            .filter(i => i.includes('tr_cc_'))
            .map(i => i.substr(6));

          dispatch({
            type: "auth.set_user",
            user: {userId: userId, groups: groups, managerOf: managerOf},
          });
        }
        //user is logged in
        logged_in = true;
        radar_url = "radar/get_internal";
        console.log("Logged in");
      } catch (e) {
        dispatch({
          type: "auth.set_user",
          user: null,
        });
        console.log("Logged out", e);
      }

      dispatch({
        type: "auth.set_login",
        logged_in: logged_in,
      });

      var dData = [];
      if(logged_in) {
        try {
          dData = await API.get("TechRadar", "radar/get_defaults");
        } catch (e) {
          console.error("Failure loading defaults", e);
        }
      }

      dispatch({
        type: "defaults.fetch_data",
        data: dData,
      });

      try {
        const data = await API.get("TechRadar", radar_url);
        dispatch({
          type: "data.fetch_data",
          data: data.filter(d => d.label),
        });
      } catch (e) {
        console.error("Failure loading data", e);
      }

      dispatch({
        type: "set_state",
        state: "ready",
      });
    };

    init_load();
  }, []);

  const updateEntry = async (entry) => {
    const myInit = {
      body: JSON.stringify(entry)
    }

    try {
      const response = await API.post("TechRadar", "radar/insert_tech",myInit)
      if(response.result === 'OK') {
        dispatch({
          type: "data.update_tech",
          tech: response.data,
        });
      } else {
        console.log(`insert_tech returned ${response.result}: ${response.message}`)
      }
      return response;
    } catch (e) {
      console.error("Failure updating data", e);
      return {result: 'error', error: e};
    }
  }

  const passivateEntry = async (entry) => {
    const myInit = {
      body: JSON.stringify(entry)
    }

    try {
      const response = await API.post("TechRadar", "radar/passivate_tech",myInit)
      if(response.result === 'OK') {
        if(response.data) {
          dispatch({
            type: "data.update_tech",
            tech: response.data,
          });
        } else {
          dispatch({
            type: "data.remove_tech",
            label: entry.name,
          });
        }
      } else {
        console.log(`passivate_tech returned ${response.result}: ${response.message}`)
      }
      return response;
    } catch (e) {
      console.error("Failure updating data", e);
      return {result: 'error', error: e};
    }
  }

  const deleteDefaults = async (idList) => {
    try {
      const response = await API.post("TechRadar", "radar/remove_defaults",
        { body: JSON.stringify(idList) }
      );
      if(response.result === 'OK') {
        dispatch({
          type: "defaults.remove_data",
          idList: idList
        })
      }
    } catch (e) {

    } finally {

    }
  }

  const insertDefault = async (entry) => {
    const myInit = {
      body: JSON.stringify(entry)
    }

    try {
      const response = await API.post("TechRadar", "radar/insert_default",myInit)
      if(response.result === 'OK') {
        dispatch({
          type: "defaults.insert_data",
          data: response.data,
        });
      } else {
        console.log(`insert_default returned ${response.result}: ${response.message}`)
      }
      return response;
    } catch (e) {
      console.error("Failure updating data", e);
    }
  }


  return (
    <StoreContext.Provider value={
        { state, dispatch, updateEntry, deleteDefaults, insertDefault, passivateEntry }
      }>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
