import React, { useState, useRef } from "react";
import { useStore } from "../../utils/store";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";



function AddDefaultForm(props) {

  const [curEntry, setCurEntry] = useState(
    { groupName: props.group,
      itemId: "",
      itemName: "",
      status: "active",
      _type: "Default",
    }
  );

  const formRef = useRef(null);
  const { insertDefault } = useStore();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setCurEntry({ ...curEntry,  [name]: value });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await insertDefault(curEntry);
    if (result.result === 'OK') {
      setCurEntry({ ...curEntry, itemId: "", itemName: "" });
      formRef.current.reset();
    } else {
      //TODO some (error) message to user
      console.error(result.result);
    }
  }

  const handleClear = async (event) => {
    event.preventDefault();
    setCurEntry({ ...curEntry, itemId: "", itemName: "" });
    formRef.current.reset();
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id="defaultsEditForm">
      <h1>{curEntry.groupName}</h1>
      <Row sm={12}>
        <Form.Label column sm={1}>ID</Form.Label>
        <Form.Control className="col-lg-3" type="text" name="itemId" onChange={handleChange} onBlur={handleChange} defaultValue={curEntry.itemId} />
        <Form.Label column sm={1}>Name</Form.Label>
        <Form.Control className="col-lg-3" type="text" name="itemName" onChange={handleChange} onBlur={handleChange} defaultValue={curEntry.itemName}/>
        <div className="col-sm-2 formButtons">
          <Button className="btn-red" size="sm" onClick={handleClear}>
            Clear
          </Button>
          <Button className="btn-blue buttonRight"
            size="sm" variant="primary" type="submit"
            disabled={!curEntry.itemId || !curEntry.itemName}>
            Add New
          </Button>
        </div>
      </Row>
    </Form>
  );
}
export default AddDefaultForm;
