import React, {Fragment} from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button'
import '../button/Button.css'
import {  useStore, StoreProvider } from "../../utils/store"

import './PageWrapper.css'
import Header from "../header/Header"
import Info from "../info/Info"
import TechRadar from "../techRadar/TechRadar"
import AdminPopUp from "../admin/AdminPopup"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

function PageWrapper () {
  const {state} = useStore();


    return (
        <div className="pageBackground">
            <Header
            logo={state.logged_in ? "/logo/digia_logo_internal.png" : "/logo/digia-logo.svg"}
            text={state.logged_in ? "Tech Radar internal" : "Tech Radar"}
            logged_in = {state.logged_in}
            />
            <StoreProvider>
                <Container className="pageContentArea">
                  <Row>
                    <Info
                    shortText="Tech Radar on Digian teknologiakartta ja lista teknologioista, ohjelmointikielistä, kehyksistä, tuotteista, alustoista ja työvälineistä.
                        Lue tarkempi kuvaus Digian Tech Radarista..."
                    infoText={<Fragment>Tech Radarissa teknologiat, ohjelmointikielet, kehykset, tuotteet, alustat ja työvälineet on ryhmitelty neljälle eri kehälle
                        kuvaamaan niiden tarkoitusta ja käyttöä Digialla.
                        <br />
                        <ul>
                            <li>
                                <b>Primary I</b> &mdash; Teknologiat, joita käytämme
                                aktiivisesti ja joilla tehtyjä ratkaisuja tarjoamme
                                aktiivisesti. Tällä kehällä olevat teknologiat ovat jo
                                koestettuja ja hyväksi todettuja. Mikäli saamme itse valita
                                vapaasti ratkaisumme teknologiat, tulisi ne ottaa tästä kehästä.
                                Osaamisen kehittämisessä ja rekrytoinnissa panostamme nimenomaan
                                tämän kehän teknologioihin.
                            </li>
                            <li>
                                <b>Primary II</b> &mdash; Hyvin tärkeitä teknologioita, joilla
                                toimitamme ratkaisuja - ylläpidämme laajaa osaamista. Monet
                                tämän kehän teknologioista tulevat asiakasohjauksesta eli
                                asiakkaat haluavat ratkaisunsa niillä. Lisäksi kehällä on
                                tiettyjä teknologioita, mitä voimme käyttää merkittävästikin,
                                mutta emme Digian laajuisesti. Tämän kehän teknologioita voi
                                siis valita vapaasti ratkaisuihin, jos Primary I teknologia ei
                                jostain syystä sovellu ratkaisuun.
                            </li>
                            <li>
                                <b>Trial</b> &mdash; Tällä kehällä sijaitsevat teknologiat,
                                joiden suhteen olemme vielä epävarmoja. Teknologiat voivat olla
                                uusia potentiaalisia, jotka voivat liikkua sisäänpäin
                                osoittaessaan kypsyystasonsa. Toisaalta tällä kehällä voi myös
                                olla teknologioita, jotka koemme olevan elinkaarensa päässä,
                                mutta riippuen monista seikoista, emme vielä ole niistä täysin
                                luopumassa. Teknologia voi siis siirtyä tältä kehältä sisään-
                                tai ulospäin, mutta useimmiten suunta tällä kehäällä on
                                sisäänpäin.
                            </li>
                            <li>
                                <b>Hold</b> &mdash; Uloimman kehän teknologiat ovat sellaisia,
                                joita seuraamme, mutta emme vielä ole käyttämässä tai joita
                                Digialla on käytetty aiemmin, mutta joiden käyttöä pyrimme tällä
                                hetkellä välttämään.
                            </li>
                        </ul>

                        <p>Ikonien selitykset</p>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> &mdash;
                                teknologia ei ole ollut aiemmin Tech Radarilla, vaan on uusi
                                nosto edellisen päivityksen jälkeen
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon> &mdash;
                                teknologia siirtyy kohti sisäkehiä, eli kohti primary I:stä.
                                Trendi on nouseva.
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon> &mdash;
                                teknologia siirtyy kohti ulkokehää tai lopulta pois koko Tech
                                Radarilta. Trendi on laskeva.
                            </li>
                        </ul></Fragment>}
                        contact = {state.logged_in && <Fragment><b>Lisätietoja: <a href="mailto:ctooffice@digia.com">ctooffice@digia.com</a></b></Fragment>}
                    />
                  </Row>
                  <Row noGutters>
                    <Col align="begin" className="updateButtons noPadding">
                        <Button
                            className="btn-blue updateBtn"
                            size="sm"
                            target="_blank"
                            href="https://link.webropolsurveys.com/S/B67E908B82FA707F"
                        >
                        Suggest an update
                        </Button>

                        {state.logged_in && state.user.groups.includes('tr_admin') ?
                            <AdminPopUp />
                         :
                            <Button
                                className="btn-red applyBtn"
                                size="sm"
                                target="_blank"
                                href="https://digia.com/rekrytointi/"
                            >
                            See our open positions {">"}
                            </Button>
                     }
                   </Col>
                  </Row>
                  <Row>
                    <TechRadar />
                  </Row>
                </Container>
            </StoreProvider>
        </div>
    )

}

export default PageWrapper
