import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./Dropdown.css";
import useOutsideClick from './useOutsideClick'

function Dropdown({ title, items, multiSelect = false, onChange, value }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const ref = useRef();

  const  handleOnClick = item => {
    if (!value.some((current) => current === item)) {
      if (!multiSelect) {
        onChange([value]);
      } else if (multiSelect) {
        onChange([...value, item]);
      }
    } else {
      let selectionAfterRemoval = value;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current !== item
      );
      onChange([...selectionAfterRemoval]);
    }
  }

  const isItemInSelection = item => {
    if (value.some((current) => current === item)) {
      return true;
    }
    return false;
  }

  useOutsideClick(ref, () => {
    if (open) setOpen(false);
  });

  const getTitle = title => {
    return title.split(" ").join("")
  }

  return (
    <div className={`top${getTitle(title)}`}>
      <nav
        ref={ref}
        className="menu"
        role="button"
        onKeyPress={() => toggle(!open)}
        onClick={() => toggle(!open)}
      >
        <ul>
          {title} 
         
          {open ? (
            <FontAwesomeIcon
              className="dropdownIcon"
              icon={faAngleUp}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="dropdownIcon"
              icon={faAngleDown}
            ></FontAwesomeIcon>
          )} 
          {value.length> 0 && <p className="filterAmount">{value.length}</p>}
        </ul>
        {open && (
          <ul className="menu-list">
            {items.map((item) => (
              <li 
                key={item.id} 
                className={`${isItemInSelection(item.id) ? "active" : ""}`}
                onClick={() => handleOnClick(item.id)}>
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </nav>
    </div>
  );
}

export default Dropdown;
