import React from "react";
import QuadrantRings from "./QuadrantRings";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./QuadrantTechnologies.css";

function QuadrantTechnologies(props) {
  return (
    <>
      <h6>{props.quadrant.name}</h6>

      <Container className="scroll">
        <Row>
          <Col sm={6}>
            <QuadrantRings
              ring={props.rings[0]}
              entries={props.entries}
              quadrant={props.quadrant}
              logged_in={props.logged_in}
            />
            <QuadrantRings
              ring={props.rings[1]}
              entries={props.entries}
              quadrant={props.quadrant}
              logged_in={props.logged_in}
            />
          </Col>

          <Col sm={6}>
            <QuadrantRings
              ring={props.rings[2]}
              entries={props.entries}
              quadrant={props.quadrant}
              logged_in={props.logged_in}
            />
            <QuadrantRings
              ring={props.rings[3]}
              entries={props.entries}
              quadrant={props.quadrant}
              logged_in={props.logged_in}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default QuadrantTechnologies;
